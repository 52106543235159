@import "../css/global.scss";

.section.gallery {
  padding: 20px 0;
  background-color: $blue;

  .slick-slider {
    height: 300px;
    overflow: hidden;

    .slick-slide {
      margin: 0 10px;

      img {
        object-fit: cover;
        height: 100%;
        max-height: 300px;
      }
    }
  }
}
