
#residential-services, #commercial-services {

  .container {
    padding: 40px 0;
  }

  .builds-wrap {
    display: flex;
    justify-content: center;

    .MuiGrid-item {
      cursor: pointer;
    }

    .build {
      .link-wrap:before {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));
      }

      &:hover {
        .link-wrap:before {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
        }
      }
    }
  }
}
