.text-center {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Optional: Hover effect */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.modalCloseBtn {
  border-radius: 50%;
  color: #eee;
  border: none;
  background-color: red;
  cursor: pointer;
  font-size: 20px;
  padding: 6px 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.container-modal {
  padding: 0;
  margin: 0 auto;
}

.cta {
  .container-modal {
    form {
      box-shadow: none;
      margin: 0;
    }
  }
}

@media all and (max-width: 1023px) {
  #custom-modal-id {
    width: 80% !important;
  }
}

@media all and (max-width: 768px) {
  #custom-modal-id {
    width: 90% !important;
    height: 80vh !important;
  }
}
